<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card>
        <b-form>
          <b-row>
            <b-col md="12">
              <div align="center" class="text-center">
                <h4>Cover</h4>
                <b-media class="mb-1 mr-auto">
                  <b-img
                    class=""
                    ref="previewEl"
                    :src="img"
                    width="400px"
                    height="300px"
                  />
                </b-media>
                <div class="mb-4">
                  <b-button variant="primary" @click="$refs.refInputEl.click()">
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @change="uploadImage"
                    />
                    <feather-icon icon="EditIcon" class="" />
                  </b-button>
                </div>
              </div>
            </b-col>
            <!-- category -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="category"
                rules="required"
              >
                <b-form-group
                  label="Category"
                  label-for="category"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="category"
                    v-model="categorySelect"
                    :state="categorySelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="categoryOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- brand -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="brand"
                rules="required"
              >
                <b-form-group
                  label="Brand"
                  label-for="brand"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="brand"
                    v-model="brandSelect"
                    :state="brandSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="brandOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- active -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="active"
                rules="required"
              >
                <b-form-group
                  label="Active"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="activeSelect"
                    :state="activeSelect === null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Purchase Date -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Purchase Date"
                rules="required"
              >
                <b-form-group
                  label="Purchase Date"
                  label-for="purchase date"
                  :state="errors.length > 0 ? false : null"
                >
                  <flat-pickr
                    v-model="itemData.purchse_date"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- is_guide Status -->
            <b-col cols="6">
              <b-row>
                <b-col> <h4>Is Guide :</h4></b-col>
                <b-col>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      class="d-flex"
                      id="checkbox-group-2"
                      button-variant="outline-primary"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <b-form-radio v-model="selected" v-bind:value="true"
                        >Yes</b-form-radio
                      >
                      <b-form-radio
                        class="ml-1"
                        v-model="selected"
                        v-bind:value="false"
                        >No</b-form-radio
                      >
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <!-- submit button -->
            <b-col md="12">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-card title="Product Status">
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col
                md="4"
                class="d-flex justify-content-center align-items-center"
              >
                <!-- Avability -->
                <b-card-text v-if="productStatus === 'enabled'"
                  ><span style="color: #0d6efd">Status: </span>-
                  <b-badge variant="success">{{ productStatus }}</b-badge>
                </b-card-text>
                <b-card-text v-if="productStatus === 'disabled'"
                  ><span style="color: #0d6efd">Status: </span> -
                  <b-badge variant="danger">{{ productStatus }}</b-badge>
                </b-card-text>
                <b-card-text v-if="productStatus === 'review'"
                  ><span style="color: #0d6efd">Status: </span> -
                  <b-badge variant="warning">{{ productStatus }}</b-badge>
                </b-card-text>
                <b-card-text v-if="productStatus === 'rejected'"
                  ><span style="color: #0d6efd">Status: </span> -
                  <b-badge variant="dark">{{ productStatus }}</b-badge>
                </b-card-text>
              </b-col>
              <b-col md="8">
                <b-button block v-b-modal.modal-status variant="info">
                  Update Status
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-modal
            id="modal-status"
            cancel-variant="outline-secondary"
            @ok="updateStatus"
            ok-title="Ok"
            cancel-title="Close"
            centered
            title="Activate Brand"
          >
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="required"
            >
              <b-form-group
                label="Status"
                label-for="status"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="status"
                  v-model="statusSelect"
                  :state="statusSelect === null ? false : true"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :selectable="
                    (option) => !option.value.includes('select_value')
                  "
                  label="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <b-form-checkbox
              id="checkbox-1"
              v-model="disabled"
              v-show="statusSelect.value == 'disabled'"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
            >
              Force Disabled
            </b-form-checkbox>
          </b-modal>
        </b-row>
      </b-card>
    </validation-observer>

    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values , index) in errors_back" :key="index">
          <li v-for="(value , valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'

export default {
  components: {
    flatPickr,
  },
  watch: {
    statusSelect: function () {
      this.disabled = false
    },
  },
  data() {
    return {
      selected: [],
      img: '',
      showDismissibleAlert: false,
      errors_back: [],
      id: 0,
      setupCover: '',
      categorySelect: {
        text: '',
        value: '',
      },
      categoryOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      brandSelect: {
        text: '',
        value: '',
      },
      brandOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      disabled:false,
      productStatus: null,
      statusSelect: {
        text: '',
        value: '',
      },
      statusOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'enabled',
          text: 'Enabled',
        },
        {
          value: 'disabled',
          text: 'Disabled',
        },
        {
          value: 'review',
          text: 'Review',
        },
        {
          value: 'rejected',
          text: 'Rejected',
        },
      ],
      activeSelect: {
        text: '',
        value: '',
      },
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '1',
          text: 'True',
        },
        {
          value: '0',
          text: 'False',
        },
      ],
      itemData: {
        cover: '',
        active: '',
        status: '',
        is_guide: false,
        category_id: '',
        brand_id: '',
        purchse_date: null,
      },
      required,
    }
  },
  created() {
    this.id = this.$route.params.id
    // get item Data
    this.fetchData()

    // get categories index
    axios
      .get('categories')
      .then((result) => {
        const data = result.data.data
        for (let cat in data) {
          if (data[cat].translation != null) {
            this.categoryOptions.push({
              value: data[cat].id.toString(),
              text: data[cat].translation.name,
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
    // get brands index
    axios
      .get('brands')
      .then((result) => {
        const data = result.data.data
        for (let bran in data) {
          if (data[bran].translation != null) {
            this.brandOptions.push({
              text: data[bran].translation.name,
              value: data[bran].id.toString(),
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
  methods: {
    fetchData() {
      axios
        .get('items-guide/' + this.id)
        .then((result) => {
          const data = result.data.data
          this.itemData.purchse_date = data.purchse_date
          this.img = data.cover
          if (data.active == 1) {
            this.activeSelect.text = 'True'
            this.activeSelect.value = '1'
          } else {
            this.activeSelect.text = 'False'
            this.activeSelect.value = '0'
          }
          this.productStatus = data.status
          if (data.status == 'enabled') {
            this.statusSelect.text = 'Enabled'
            this.statusSelect.value = 'enabled'
          } else if (data.status == 'disabled') {
            this.statusSelect.text = 'Disabled'
            this.statusSelect.value = 'disabled'
          } else if (data.status == 'review') {
            this.statusSelect.text = 'Review'
            this.statusSelect.value = 'review'
          } else {
            this.statusSelect.text = 'Rejected'
            this.statusSelect.value = 'rejected'
          }
          if (data.category.translation != null) {
            this.categorySelect.value = data.category.id.toString()
            this.categorySelect.text = data.category.translation.name
          }
          if (data.brand.translation != null) {
            this.brandSelect.value = data.brand.id.toString()
            this.brandSelect.text = data.brand.translation.name
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    updateStatus() {
      axios
        .get(
          'items/' +
            this.id +
            '/change-status/' +
            this.statusSelect.value +
            '/' +
            this.disabled
        )
        .then((result) => {
          this.$swal({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
          })
          this.fetchData()
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // submit update
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.itemData.active = this.activeSelect.value
          this.itemData.status = this.statusSelect.value
          this.itemData.is_guide = this.selected
          this.itemData.category_id = this.categorySelect.value
          this.itemData.brand_id = this.brandSelect.value
          if (this.img.includes(',')) {
            this.itemData.cover = this.img.split(',').map((item) => item.trim())
            this.itemData.cover = this.itemData.cover[1]
          }
          if (this.img == '' || this.itemData.cover == '') {
            delete this.itemData.cover
          }

          axios
            .put('items-guide/' + this.id + '/update', this.itemData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // encode cover picture
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        this.img = e.target.result
      }
      reader.readAsDataURL(file)
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style lang="scss">
</style>
